"use client";

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { PropsWithChildren, ReactNode, useEffect, useState } from "react";

const DefaultOnSSR = () => null;

interface ClientRenderOnlyProps extends PropsWithChildren {
  onSSR?: ReactNode | undefined;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {retryOnMount: false, refetchOnMount: false, refetchOnWindowFocus: false}
  }
});
const persister = createSyncStoragePersister({
  storage: globalThis.window ? window.sessionStorage : null,
});

export default function ClientRenderOnlys({ onSSR, children }: ClientRenderOnlyProps) {
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    setCanRender(true);
  }, []);

  if (!canRender) {
    return <>{onSSR ? onSSR : <DefaultOnSSR></DefaultOnSSR>}</>
  }


  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      {children}
    </PersistQueryClientProvider>
  );
}
